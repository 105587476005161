@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.furnitureTypeCard {
  margin-bottom: 30px;
  @include fadeIn(300ms);

  @include max-screen(768px) {
    margin-top: 20px;
  }

  @include mobile(all) {
    margin: 10px;
  }
}

.container {
  background: $paper;
  margin: auto;
  min-height: 473px;
  max-width: 1220px;
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(4, auto);
  padding: 20px 10px;
  overflow: hidden;

  .title {
    grid-column: 1/2;
    grid-row: 1;
  }

  .text {
    grid-column: 1;
    grid-row: 2;
  }

  .mainImage {
    grid-column: 2;
    grid-row: 1/3;
  }

  .scrollerContainer {
    grid-column: 1 / 3;
    grid-row: 3;
  }

  .buttonContainer {
    grid-column: 1 / 3;
    grid-row: 4;
  }

  @include max-screen(1220px) {
    min-height: 450px;
    max-width: 710px;
  }

  @include min-screen(1221px) {
    padding: 0 0 0 100px;
    grid-template-columns: 58% 42%;
    grid-template-rows: repeat(3, auto);

    .title {
      grid-column: 1;
    }

    .mainImage {
      grid-row: 1/4;
    }

    .scrollerContainer {
      grid-column: 1;
      grid-row: 3;
    }

    .buttonContainer {
      grid-column: 2;
      grid-row: 3;
    }
  }
}

.title {
  @include subheader-font-demi;
  text-transform: uppercase;
  margin-bottom: 10px;

  @include min-screen(1221px) {
    margin-top: 60px;
    margin-bottom: 0;
  }
}

.text {
  @include body-font-2;
  color: $charcoal;
  max-width: 400px;
}

.mainImage {
  position: relative;
  background-color: #fff;

  img {
    display: block;
    width: 100%;
  }

  @include min-screen(1220px) {
    &:before {
      content: '';
      position: absolute;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      top: 0;
      bottom: 0;
      left: 0;
      width: 35px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.04);
    pointer-events: none;
  }
}

.scrollerContainer {
  overflow: auto;
  margin-bottom: 20px;

  @include min-screen(1220px) {
    padding-right: 80px;
  }

  h3 {
    @include body-font-3;
    color: $charcoal;
  }

  .scroller {
    margin-left: -5px;
  }

  .furnitureType {
    width: 105px;
    flex-shrink: 0;
    padding: 8px 0 0 7.5px;

    img {
      width: 100%;
    }

    @include min-screen(1220px) {
      width: 135px;
    }
  }
}

.carousel {
  margin-left: -35px;

  :global {
    .slick-arrow {
      @include max-screen(730px) {
        margin-top: -75px;
      }
    }

    .slick-arrow:before {
      background-image: url('/img/icons/general/arrow-chevron-left-cta.svg');
      height: 20px;
      filter: brightness(0);
    }

    .slick-disabled {
      opacity: 0.3;

      &:before {
        background-image: url('/img/icons/general/arrow-chevron-left.svg');
      }
    }
  }
}

.buttonContainer {
  @include min-screen(1221px) {
    z-index: 1;
    position: relative;

    .button {
      position: absolute;
      bottom: 30px;
      width: auto;
      display: inline-block;
      left: 25%;
    }
  }
}

.button {
  text-align: center;
}

.furnitureType {
  cursor: pointer;
  padding: 23px 7.5px 0;
  outline: none;

  &.active {
    .furnitureTypeName {
      color: $charcoal;
    }

    .furnitureTypeImage {
      border-color: $charcoal;
    }
  }

  &.active,
  &:hover {
    outline: none;

    .furnitureTypeImage:after {
      background-color: transparent;
    }
  }

  @include max-screen(1220px) {
    padding-top: 12px;
  }

  img {
    outline: none;
  }

  @include mobile(all) {
    width: 105px;
    flex-shrink: 0;
    padding: 8px 0 0 7.5px;

    img {
      width: 100%;
    }
  }
}

.furnitureTypeImage {
  display: block;
  position: relative;
  border: 1px solid transparent;
  user-select: none;
  background: none;
  outline: none;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    transition: background-color 100ms linear;
    pointer-events: none;
  }

  @include mobile(all) {
    width: 100%;
  }
}

.furnitureTypeName {
  @include body-font-5;
  color: $charcoal;
  padding-top: 15px;
  text-align: center;

  @include mobile(all) {
    padding-top: 10px;
  }
}
