@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$materialPreviewDimensionsL: 124px;
$materialPreviewDimensionsS: 96px;

.container {
  display: block;
  width: 100%;
  margin: 0 auto;

  @include min-screen(992px) {
    width: 865px;
  }
}

.cfButton {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.title {
  @include h3-font-demi;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: bold;
}

.materialCategoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.materials {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  flex-wrap: wrap;
  margin: 0 0.4rem;

  @include max-screen(992px) {
    column-gap: 0.8rem;
  }
  @include max-screen(480px) {
    column-gap: 0.8rem;
  }
}

.materialPreview {
  text-align: center;
  width: $materialPreviewDimensionsL;
  img {
    width: $materialPreviewDimensionsL;
  }
  @include max-screen(480px) {
    width: $materialPreviewDimensionsS;
    img {
      width: $materialPreviewDimensionsS;
    }
  }
}
