@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  position: relative;
  padding-right: 2px;
}

.rangeSliderContainer {
  padding: 0 10px;
}

.rcSlider {
  display: block;
  position: relative;
  height: 16px;
  padding: 8px 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 10px;
  width: 100%;

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  :global(.rc-slider-rail) {
    position: absolute;
    width: calc(100% + 20px);
    background-color: $grey-sky;
    height: 1px;
    left: -10px;
  }
  :global(.rc-slider-track) {
    position: absolute;
    left: 0;
    height: 2px;
    background-color: $ash;
  }
  :global(.rc-slider-handle) {
    position: absolute;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border: none;
    background-color: $ash;
    border-radius: 100%;
    padding: 8px;
    margin-top: -8px;
    margin-left: 0px;
    outline: transparent;
  }
  :global(.rc-slider-handle-active:active) {
    border-color: $mx-color-primary;
    box-shadow: 0 0 5px $mx-color-primary;
  }
  :global(.rc-slider-mark) {
    position: absolute;
    left: 0;
    width: 100%;
    top: 22px;
  }
  :global(.rc-slider-mark-text) {
    position: absolute;
    top: 2px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    cursor: pointer;
    color: $charcoal;
    white-space: nowrap;
  }
  :global(.rc-slider-mark-text:first-child) {
    margin-left: 20px !important;
    left: 0 !important;
  }
  :global(.rc-slider-mark-text:last-child) {
    transform: translateX(-100%);
    text-align: right;
    margin-left: -19px !important;
    left: 100% !important;
  }
  :global(.rc-slider-step) {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }
  :global(.rc-slider-dot) {
    position: absolute;
    bottom: -1px;
    height: 9px;
    border-left: 1px solid $grey-sky;
    background-color: $white;
    cursor: pointer;
  }
  :global(.rc-slider-dot:first-child),
  :global(.rc-slider-dot:last-child) {
    display: block;
  }
  :global(.rc-slider-dot:first-child) {
    left: -10px !important;
  }
  :global(.rc-slider-dot:last-child) {
    left: calc(100% + 9px) !important;
  }
  :global(.rc-slider-dot-active) {
    border-color: $ash;
  }
}

.title {
  @include body-font-2;
  color: $charcoal;
}

.label {
  @include body-font-4;
  color: $ash;
  width: 60px;
  margin-top: 2px;
}
