@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../../scss/functions';
@import '../../../../../../scss/config';
@import '../../../../../../scss/mixins';
@import '../../../../../../scss/vars';
@import '../../../../../../scss/media_queries';

.container {
  display: flex;
  flex-direction: row;
  margin: auto;
}
.pageButton {
  width: 48px;
  height: 48px;
  background-color: $light-ice;
  margin: 3px;
}
.pageButtonMobile {
  display: none;
}

// Mobile
@include max-screen(767px) {
  .pageButton {
    display: none;
  }
  .pageButtonMobile {
    display: block;
    width: 100%;
    height: 41px;
    background-color: $light-ice;
    margin: 0 5px;
  }
}
