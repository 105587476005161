@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.button {
    display: block;
    margin-bottom: 25px;
    user-select: none;
  
    @include max-screen(768px) {
      margin: 10px 0 40px;
      text-align: center;
    }
  
    @include max-screen(767px) {
      margin: 0;
      width: 100%;
    }
  }
  
  .buttonMarginTop {
    display: block;
    margin-top: 35px;
  }
  
  .buttonMarginBottom {
    margin-bottom: 35px;
  }
  
  .cartButton {
    background-color: $mx-color-primary !important;
    border-color: $mx-color-primary !important;
  
    &:hover {
      background-color: $mx-color-primary-dark !important;
      border-color: $mx-color-primary-dark !important;
    }
  
    @include max-screen(768px) {
      margin: auto;
    }
  
    @include max-screen(767px) {
      margin-top: 15px;
    }
  }
  
  .panelContainer {
    padding: 74px 40px 40px 25px;
    position: relative;
  
    @include max-screen(1024px) {
      min-height: 100%;
      width: 100%;
    }
  
    @include max-screen(992px) {
      padding: 38px 30px 30px 20px;
    }
  
    @include max-screen(767px) {
      padding: 10px 10px 30px;
      overflow: hidden;
    }
  }
  
  .panelBody {
    height: 100%;
  }
  
  .panelColumn {
    @include no-side-padding;
  }
  
  .panelAuthorContainer {
    background: $white;
    text-align: left;
  }
  
  .panelAuthor {
    align-items: center;
    background-color: $paper;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 20px;
  
    @include min-screen(769px) {
      height: 195px;
      width: 195px;
    }
  }
  
  .panelAuthorAvatar {
    height: auto;
    width: 100%;
  }
  
  .panelAuthorSite {
    @include mx-link--secondary;
  }
  
  .panelLogoInfoCol {
    color: $charcoal;
    padding-right: 0;
    padding-left: 15px;
  
    @include max-screen(992px) {
      padding-left: 10px;
    }
  
    @include mobile(landscape) {
      padding: 0 0 0 30px;
    }
  }
  
  .photoCreditsSection {
    @include max-screen(767px) {
      background-color: $paper;
      padding: 0 20px 32px;
    }
  }
  
  .panelPhotoBy {
    @include body-font-3;
    color: $charcoal;
  
    @include max-screen(767px) {
      text-align: left;
    }
  }
  
  .panelVendor {
    @include body-font-2;
    padding-top: 12px;
    color: $charcoal;
  
    @include max-screen(992px) {
      hyphens: auto;
    }
  
    @include max-screen(767px) {
      text-align: left;
    }
  }
  
  .panelProductInfo {
    line-height: 1.5em;
  
    h5 {
      @include body-font-3;
      color: $black;
      margin: 35px 0 15px;
    }
  
    p {
      @include mx-font-body;
    }
  
    @include max-screen(768px) {
      margin: 20px 0 35px;
    }
  }
  
  .panelFull {
    background: $paper;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  
    @include mobile(all) {
      position: relative;
    }
  
    @include min-screen(1025px) {
      margin-left: auto;
    }
  
    @include max-screen(1024px) {
      border: 0 !important;
      box-shadow: none !important;
      display: inline-block;
    }
  
    @include max-screen(768px) {
      height: auto;
    }
  
    @include max-screen(767px) {
      text-align: left;
    }
  }
  
  .panelSidebarContent {
    padding-right: 29px;
  
    @include max-screen(992px) {
      padding-right: 15px;
    }
  }
  
  .panelPictureWide {
    height: auto;
    max-height: calc(100vh - 200px);
    @include max-screen(992px) {
      object-fit: contain;
      max-width: 100%;
      max-height: calc(100vh - 200px);
      margin-left: auto;
      margin-right: auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  
  .panelPictureSpaced {
    margin-bottom: 20px;
  }
  
  .panelInfo {
    margin: 28px;
  
    @include max-screen(992px) {
      margin: 19px 19px 30px;
    }
  
    @include max-screen(767px) {
      background-color: $paper;
      margin: 0;
      padding: 22px 20px 15px;
    }
  }
  
  .panelInfoBlogName {
    @include mx-font-h1-alt;
    color: $black;
    line-height: rem(29);
    padding: 20px 0;
  }
  
  .panelInfoTitle {
    @include body-font;
    color: $charcoal;
    margin-bottom: 20px;
    text-align: left;
  
    @include max-screen(767px) {
      margin-bottom: 13px;
    }
  }
  
  .panelInfoDescriptionLeft {
    @include body-font-2;
    color: $charcoal;
    text-align: left;
    margin: 0;
  }
  
  .panelContentContainer {
    padding: 0 45px;
  
    @include max-screen(768px) {
      padding: 0 30px;
    }
  }
  
  .panelProductContainer {
    margin: 0 auto;
  
    @include max-screen(767px) {
      margin: 30px 0;
    }
  }
  
  .panelFootnote {
    @include mx-font-product-caption;
    color: $black;
    margin-top: 30px;
  }
  
  .panelImageDescription {
    @include mx-font-body-2;
    margin-bottom: 50px;
  }
  
  .hideOnMobile {
    display: block;
  
    @include max-screen(767px) {
      display: none !important;
    }
  }
  
  .showOnMobile {
    display: none;
  
    @include max-screen(767px) {
      display: block !important;
    }
  }
  
  .showOnTabletPortrait {
    display: none;
  
    @include tablet(portrait) {
      display: block !important;
    }
  }
  
  .hideOnSmallScreen {
    @include max-screen(767px) {
      display: none !important;
    }
  }
  
  .showOnSmallScreen {
    display: none;
  
    @include max-screen(767px) {
      display: block !important;
    }
  }
  
  .productLayout {
    @include max-screen(767px) {
      .panelFull {
        background: $white;
      }
    }
  }
  