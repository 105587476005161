@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.nonClickable {
  cursor: unset !important;
}
.mediaPreview {
  cursor: pointer;
  position: relative;
  top: 30px;
}

.mediaPreviewContainer {
  padding: 5px;
}

.container {
  margin-bottom: 20px;
  .slider {
    :global {
      .slick-arrow {
        &:before {
          background-color: $light-peach;
          padding: 20px;
          max-width: 2em;
          max-height: 2em;
        }
        &:hover:before {
          opacity: 0.6;
        }
      }
    }
  }

  :global(.slick-dots) {
    @include max-screen(768px) {
      border: none;
      padding: 0;
    }
  }
  @include max-screen(768px) {
    margin-bottom: 20px;
  }
}

.title {
  @include h3-font-demi;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
}
