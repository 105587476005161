@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.modelTitle {
  @include h3-font-demi;
  margin: 30px 30px 26px 30px;
  text-align: center;

  @include max-screen(767px) {
    @include subheader-font-demi;
    text-align: center;
    margin: 30px 20px;
  }
}

.modelContainer {
  margin: 40px;

  @include max-screen(992px) {
    margin: 10px;
  }
}

.modelImage {
  margin-bottom: 20px;
}

.furnitureTypeName {
  @include subheader-font-demi;
  color: $charcoal;
  padding-top: 15px;
  text-align: center;

  @include max-screen(767px) {
    padding-top: 10px;
  }
}
