@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.links {
  padding: 20px 50px 0;

  @include max-screen(768px) {
    padding: 20px 10px 0;
  }
}

.link {
  @include mx-link;
  color: $mx-color-grey-2 !important;
  display: inline-block;
  font-size: rem(12);
  letter-spacing: rem(1);
  margin: 15px 0;
  text-align: center;
  white-space: nowrap;
  width: 25%;

  div {
    margin-bottom: 0 !important;
  }

  &:hover {
    color: $mx-color-primary !important;
    border-bottom: none !important;

    .icon {
      path,
      ellipse,
      polyline,
      circle,
      line,
      polygon {
        stroke: $mx-color-primary !important;
      }
    }
  }

  @include max-screen(1200px) {
    margin-bottom: 30px;
    width: 33%;
  }

  @include max-screen(768px) {
    font-size: rem(10);
  }

  @include max-screen(601px) {
    width: 50%;
  }

  @include max-screen(500px) {
    font-size: rem(9);
  }
}

.icon {
  height: 40px;
  margin-bottom: 15px;
  width: 40px;

  path,
  ellipse,
  polyline,
  circle,
  line,
  polygon {
    stroke: $mx-color-grey-2;
  }
}
