@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: flex;
  flex-wrap: wrap;

  .preview {
    width: 33.3333%;
    margin-bottom: -10px;

    @include max-screen(1023px) {
      width: 50%;
    }

    @include max-screen(767px) {
      width: 100%;
    }
  }

  .title {
    display: inline-block;
    position: relative;
    left: -7px;
    top: -20px;

    @include max-screen(1023px) {
      top: -25px;
    }

    a {
      @include mx-link--secondary;
    }
  }
}
