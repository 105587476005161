@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.gallery {
  @include mx-font-quote-caption;
  overflow: hidden;
}

.column5 {
  .column {
    width: 20%;
  }
}

.column4 {
  .column {
    width: 25%;
  }
}

.column3 {
  .column {
    width: 33.33%;
  }
}

.column2 {
  .column {
    width: 50%;
  }
}

.column {
  float: left;
  width: 20%;
}

.item {
  padding: 0;
  cursor: pointer;

  > div {
    width: 100%;
  }
}

.newsletter {
  margin: 40px 0;

  @include max-screen(767px) {
    margin: 20px 0;
  }
}

.title {
  @include mx-font-body-2;
  color: $charcoal;
  line-height: rem(18);
}

.subtitle {
  @include mx-font-product-caption;
  color: $ash;
  line-height: rem(16);
}
