@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  color: $text-color-primary;
  margin-top: 40px;

  @include max-screen(767px) {
    margin: 30px 15px 0;
  }
}

.header {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include mx-font-h2;
  text-align: center;
  margin-bottom: 40px;
}

.scoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @include max-screen(767px) {
    flex-direction: column;
    align-items: unset;
  }
}

.score {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  >span {
    padding-bottom: 3px;
  }

  .starsContainer {
    margin-left: 24px;
  }

  @include max-screen(767px) {
    justify-content: center;
  }
}

.starTitle {
  height: 20px;
  width: auto;
}

.reviewsSubheading {
  @include body-font;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.filtersContainer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  >span {
    text-transform: uppercase;
    color: $charcoal;
    font-size: rem(13);
    line-height: rem(16);
    letter-spacing: 2px;
    font-weight: 600;
    margin-right: 15px;
  }
}

.filterBtn {
  background-color: $paper !important;
  color: $charcoal !important;

  span {
    margin: 0 !important;
  }
}

.activeFilter {
  background-color: $sand !important;
}