@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.desktopFiltersContainer {
  margin-top: 10px;
}

.filterTabsStickyContainer {
  width: 100%;
  z-index: 17 !important; // On top of discount ribbon
  background: $white;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.filterButtonStickyContainer {
  width: 100%;
  z-index: 3 !important; // On top of product grid discounts
  background: $paper;
  border-bottom: 1px solid $ice;
}

.filterTabsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.filterTabsTitle {
  @include button-cta-font;
  text-transform: uppercase;
  color: $charcoal;
  margin-right: 15px;
  flex-shrink: 0;
}

.filterTab {
  padding: 1.5px 20px;
  margin: 2px 5px;
  border: 1px solid transparent;
  transition: border-color 200ms linear, background-color 200ms linear;

  &:hover {
    background-color: $white;
    border-color: $neutral;
  }

  &:global(.Pillbox__active) {
    pointer-events: none;
  }

  &.isDisabled {
    pointer-events: none;
  }
}

.filterButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.filterButton {
  padding: 12px 49px !important;
  font-size: rem(11) !important;
  line-height: rem(15) !important;
  letter-spacing: rem(2) !important;
}

.filterOptionsContainer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 4;
  width: 100%;
  max-width: 712px;
  margin: auto;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 600ms ease-in-out 150ms;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.2);

  &.showOptions {
    max-height: 1500px;
  }
}

.filterTitle {
  @include button-cta-font;
  text-transform: uppercase;
  color: $ash;
  margin: 0 20px 0;
}

.mobileFiltersContainer {
  padding-top: 20px;
}

.mobileConfirmButtonContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  width: 100%;
  background: $white;
  text-align: center;
  border-top: 2px solid $ice;
  padding: 10px 26px;
}

.mobileConfirmButton {
  &:global(.Button__primaryCta.Button__disabled) {
    border: 1px solid $grey-sky;
    background-color: $white;
    color: $grey-sky;
    opacity: 1;
  }
}

.mobileCancelButtonContainer {
  text-align: center;
  margin: 0 0 28px;
  position: relative;

  :global(.Button__button) {
    color: $ash;
  }
}

.productsContainer {
  position: relative;
  display: flex;
  flex-direction: column;

  &:before {
    background: rgba(0, 0, 0, 0.2);
    content: '';
    min-height: 0%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
    opacity: 0;
    transition: opacity 200ms ease-in-out 5ms, min-height 1ms ease-in-out;
  }

  &.showOverlay {
    &:before {
      opacity: 1;
      min-height: 100%;
    }
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include max-screen(1280px) {
    width: auto;
  }
}

.tagContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @include body-font-2;
  background-color: $neutral;
  color: $white;
  padding: 2px 5px 2px 10px;
  margin: 12px 10px 0 0;
  transition: background-color 200ms linear;

  &:hover {
    cursor: pointer;
    background-color: $ash;
  }
}

.tagCloseButton {
  width: 18px;
  height: 18px;
  margin-left: 8px;

  :global(.Button__button) {
    display: block;
  }

  svg {
    width: 18px;
    height: 18px;

    * {
      stroke-width: 4.5px;
      stroke: $white;
    }
  }
}

.errorMessageContainer {
  width: 100%;
  max-height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: max-height 300ms 0s, opacity 300ms 0s, visibility 0s 300ms;

  &.isOpen {
    max-height: 300px;
    opacity: 1;
    visibility: visible;
    transition: max-height 300ms 0s, opacity 300ms 0s, visibility 0s 0s;
  }

  @include max-screen(1280px) {
    width: auto;
  }
}

.errorMessage {
  background-color: $sand;
  padding: 11px 45px 11px 11px;
  margin: 10px 0;
  position: relative;

  @include mobile {
    padding: 15px;
    margin: 0;
  }
}

.errorMessageText {
  @include body-font;
  text-align: center;

  b {
    @include maisonneue-demi;
  }

  @include mobile {
    width: calc(100% - 50px);
    text-align: left;
  }
}

.errorMessageCloseButton {
  position: absolute;
  right: 5px;
  top: 7px;
  width: 35px;
  height: 35px;

  :global(.Button__button) {
    display: block;
  }

  svg {
    width: 35px;
    height: 35px;

    * {
      stroke-width: 2.5px;
      stroke: $charcoal;
    }
  }

  @include mobile {
    right: 8px;
    top: 8px;
  }
}

.isLoading {
  a {
    pointer-events: none;
  }

  :global(.Carousel__slider),
  :global(.MediaPreview__imageLink),
  :global(.ProductPreview__splitButtonGroup),
  :global(.ProductPreview__customizeIconCta),
  :global(.ProductPreview__price),
  :global(.ProductPreview__sleepingSofaLabel) {
    opacity: 0;
    pointer-events: none;
  }

  :global(.MediaPreview__imageBlock),
  :global(.ProductPreview__titleText),
  :global(.ProductPreview__subtitle),
  :global(.ProductPreview__dimensions) {
    background-color: $light-ice;
    color: $light-ice;
    animation: loading 1.3s infinite linear;
  }

  @keyframes loading {
    from {
      transition: none;
    }
    to {
      background-color: $paper;
      color: $paper;
      transition: all 0.3s ease-out;
    }
  }
}

.placeholderAnimation {
  @include placeholder-animation;
}
