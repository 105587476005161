@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $white;
  position: relative;
  padding: 20px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.2);

  &.mobileLayout {
    box-shadow: none;
    padding-bottom: 5px;

    .buttonsContainer,
    .closeButton {
      display: none;
    }

    .colorOptionContainer {
      width: 50%;
    }
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.resetButton {
  margin-right: 30px;

  :global(.Button__button) {
    color: $ash;
  }

  :global(.Button__disabled) {
    color: $grey-sky;
    opacity: 1;
  }
}

.confirmButton {
  :global(.Button__button) {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    border: 1px solid;
  }

  :global(.Button__disabled) {
    background-color: transparent !important;
    color: $grey-sky;
    opacity: 1;
  }
}

.closeButton {
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 17px;
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }

  :global(.Button__button) {
    display: block;
  }

  svg {
    width: 20px;
    height: 20px;

    * {
      stroke-width: 5px;
      stroke: $charcoal;
    }
  }
}

.colorOptionsContainer {
  display: flex;
  flex-wrap: wrap;
}

.colorOptionContainer {
  display: flex;
  align-items: center;
  width: 25%;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;

    .color {
      border-color: transparent;

      &:before,
      &:after {
        opacity: 1;
      }
    }
  }

  @include mobile {
    &:hover {
      .color:not(.activeColor) {
        border-color: $grey-sky;

        &:before,
        &:after {
          opacity: 0;
        }
      }
    }
  }
}

.color {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid $grey-sky;
  position: relative;
  transition: border-color 200ms linear;
  flex-shrink: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    user-select: none;
    pointer-events: none;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 200ms linear;
  }

  &:before {
    margin: auto;
    border: 2px solid $white;
  }

  &:after {
    margin: 2px;
  }

  &.activeColor {
    border-color: transparent;

    &:before,
    &:after {
      opacity: 1;
    }
  }
}

.colorName {
  @include body-font-2;
  color: $charcoal;
}

.colorIconContainer {
  display: block;
  width: 100%;
  height: 100%;
  padding: 7px;

  div {
    height: 100%;
  }
}

.colorIcon {
  display: block;
  width: 9px;
  height: 9px;
  fill: $white;
}
