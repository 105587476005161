@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../../scss/functions';
@import '../../../../../../scss/config';
@import '../../../../../../scss/mixins';
@import '../../../../../../scss/vars';
@import '../../../../../../scss/media_queries';

$menuBottomHeight: 63px;
$optionsMenuTabsHeight: 46px;

.wrapper {
  max-height: 100%;
  transition: transform 300ms 20ms ease-out;
  top: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;

  &.extraSmall {
    transform: translateY(-180px);

    @include max-screen(992px) {
      transform: translateY(-190px);
    }

    &.withoutTabs {
      transform: translateY(calc(-180px + #{$optionsMenuTabsHeight}));

      @include max-screen(992px) {
        transform: translateY(calc(-190px + #{$optionsMenuTabsHeight}));
      }
    }
  }

  &.small {
    transform: translateY(-221px);
    transition: transform 300ms 20ms ease-out;

    @include max-screen(992px) {
      transform: translateY(-241px);
    }

    &.withoutTabs {
      transform: translateY(calc(-221px + #{$optionsMenuTabsHeight}));

      @include max-screen(992px) {
        transform: translateY(calc(-241px + #{$optionsMenuTabsHeight}));
      }
    }
  }

  &.medium {
    transform: translateY(-259px);

    @include max-screen(992px) {
      transform: translateY(-269px);
    }

    &.withoutTabs {
      transform: translateY(calc(-259px + #{$optionsMenuTabsHeight}));

      @include max-screen(992px) {
        transform: translateY(calc(-269px + #{$optionsMenuTabsHeight}));
      }
    }
  }

  &.extraMedium {
    transform: translateY(-259px);

    @include max-screen(992px) {
      transform: translateY(-373px);
    }
  }

  &.large {
    transform: translateY(-259px);
    transition: transform 300ms 20ms ease-out;

    @include max-screen(992px) {
      transform: translateY(-269px);
    }

    &.withoutTabs {
      transform: translateY(calc(-259px + #{$optionsMenuTabsHeight}));

      @include max-screen(992px) {
        transform: translateY(calc(-269px + #{$optionsMenuTabsHeight}));
      }
    }
  }

  &.extraLarge {
    transform: translateY(-259px);
    transition: transform 300ms 20ms ease-out;

    @include max-screen(992px) {
      transform: translateY(-373px);
    }
  }

  &.fullScreen {
    height: 100%;
    transform: translateY(-100%);

    .scrollableContainer {
      overflow-x: hidden;
      overflow-y: auto;

      @include min-screen(993px) {
        max-height: 100vh !important;
      }
    }
  }

  // Open state
  &.large,
  &.extraLarge,
  &.fullScreen {
    .arrow {
      transform: rotateX(180deg);
    }
  }

  &.fromFullScreen {
    max-height: 100%;
  }

  // Hidden state
  &.hidden {
    transform: translateY(0);
  }

  @include max-screen(767px) {
    position: fixed;
    z-index: 11;

    &.fullScreen {
      z-index: 13; // Over the mobile bottom menu
    }
  }

  &.withoutTabs {
    .scrollableContainer {
      padding-top: 30px;

      @include max-screen(992px) {
        padding-top: 40px;
      }
    }
  }
}

.buttonsContainer {
  top: -54px;
  position: absolute;
  right: 30px;
  margin-right: 0;
  transition: transform 300ms ease-in-out;
  width: fit-content;

  > div {
    display: inline-block;
  }

  @include max-screen(992px) {
    left: 0;
    right: 0;
    margin: auto;
  }
}

.container {
  background-color: $white;
  overflow: hidden;
  box-shadow: 0 -3px 7px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
}

.header {
  @include body-font-2;
  background: $petrol-green;
  color: $white;
  padding: 2.5px 8px;
  z-index: 4 !important;
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  z-index: 0;

  &:hover {
    cursor: pointer;
  }

  @include max-screen(992px) {
    padding: 7.5px 8px;
  }
}

.arrow {
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 50%;
  margin-top: -11px;
  transition: transform 300ms ease-in;

  &:hover {
    cursor: pointer;
  }

  svg {
    display: block;
    height: 22px;
    width: 22px;
  }

  path {
    stroke-width: 5px;
    stroke: $white;
  }

  @include max-screen(992px) {
    right: 20px;
  }

  @include max-screen(767px) {
    right: 10px;
  }
}

.undo {
  display: none;
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 50%;
  margin-top: -11px;
  transition: transform 300ms ease-in;

  &:hover {
    cursor: pointer;
  }

  svg {
    display: block;
    height: 26px;
    width: 26px;
  }

  path {
    stroke-width: 5px;
    stroke: $white;
  }

  @include max-screen(992px) {
    left: 20px;
  }

  @include max-screen(767px) {
    left: 10px;
  }
}

.showUndo {
  .undo {
    display: block;
  }
}

.pillbox {
  background: $white;
  margin-top: 30px;
  margin-bottom: -40px;
  position: relative;
  z-index: 4;

  @include max-screen(992px) {
    margin-top: 40px;
  }
}

.scrollableContainer {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: $menuBottomHeight;
  height: 100%;
}

:global(#rsg-root) {
  .wrapper {
    position: absolute;
  }
}
