@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $white;
  padding: 5px 0;
}

.containerBorderBottom {
  border-bottom: 1px solid $ice;
}

.outlined {
  background: none;
  border-bottom: none;

  .tab {
    border: 0.5px solid $ice;
    background-color: $white;
    font-size: rem(12);
    margin: 5px 2px;
    color: $charcoal;

    &:hover {
      &:not(.active) {
        background-color: $grey-sky;
        border-color: $grey-sky;
        color: $white;
      }

      &.active {
        border-color: $ash;
      }
    }
  }

  button path {
    stroke: $ash;
    stroke-width: 6px;
  }
}

.tab {
  background-color: #ebebeb;
  text-align: center;
  @include body-font-2;
  margin: 5px;
  color: $ash;
  padding: 0 10px;
  border-radius: 20px;
  flex-shrink: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.active,
  &:hover {
    background-color: $sand;
    transition: background-color 0.1s ease-in-out;
  }
}

.configuratorLayout {
  background: none;
  border-bottom: none;
  padding: 5px 7px 7px 7px;

  .tab {
    font-size: rem(13);
    color: $ash;
    border: 1px solid $ice;
    border-radius: 20px;
    padding: 2px 12px;
    margin: 2px;
    transition: all 0.3s;
    background-color: $white;

    &.active {
      color: $charcoal;
      border-color: $light-petrol;
    }

    &:hover {
      @include maisonneue-demi;
      color: $charcoal;
      border-color: $petrol-green;
    }
  }
}
