@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$trans-time: 0.15s;
$content-margin: 66px;

.jalousie {
  position: relative;
  height: 585px;
  max-width: 1220px;

  @include max-screen(992px) {
    height: 400px;
  }

  @include max-screen(767px) {
    background: $charcoal;
    overflow: hidden;
    height: 456px;
  }

  .h1 {
    min-width: 70%;
  }

  button {
    background: none;

    svg {
      width: 38px;
    }

    path {
      stroke: $white;
      stroke-width: 3px;
    }

    &:hover path {
      stroke: $white !important;
    }

    * {
      pointer-events: none;
    }
  }

  .dark button,
  .dark button:hover {
    path {
      stroke: $charcoal !important;
    }
  }
}

.heading {
  @include h3-font-demi;
  text-align: center;
  padding: 0 5px;
  margin: 50px 0 40px;

  @include max-screen(992px) {
    margin: 45px 0 30px;
  }
}

.bg {
  position: absolute;
  top: 0;
  height: 100%;
}

.imgOverlay:after,
.imgOverlay:before {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 0.15s linear;
}

.imgOverlay:after {
  background: $black;
  opacity: 0;
}

.imgOverlay:before {
  opacity: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    linear-gradient(rgba(131, 131, 131, 0.11), rgba(131, 131, 131, 0.11));
}

.darkGradient:before,
.lightGradient:before {
  opacity: 0.4;

  @include max-screen(767px) {
    opacity: 1;
  }
}

.darkGradient:before {
  @include max-screen(767px) {
    background: rgba(0, 0, 0, 0.35);
  }
}

.imgOverlayOn:after {
  opacity: 0.4;
}

.imgOverlayOn.darkGradient:before,
.imgOverlayOn.lightGradient:before {
  opacity: 0;
}

.section {
  background-repeat: no-repeat;
  background-size: cover;
  border-right: 2px solid $ice;
  cursor: pointer;
  height: 100%;
  position: absolute;
  overflow: hidden;
  transition: all $trans-time ease-in;
  will-change: left, right, background-position-x;

  @include max-screen(767px) {
    border-right: none;
    border-bottom: 3px solid white;
    background-position: center;
    height: 33.33%;
  }

  &:last-child {
    border: 0;
  }

  &.sectionOpen {
    cursor: default;

    @include max-screen(767px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 1;
    }
  }
}

.hide {
  display: none;
}

.initial {
  @include button-cta-font;
  text-transform: uppercase;
  bottom: 34px;
  color: $white;
  position: absolute;
  text-align: center;
  width: 100%;
  text-transform: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include max-screen(767px) {
    top: 24px;
    bottom: 18px;
  }

  &.initialHover {
    .h1 {
      top: 45%;
    }
  }

  .h1 {
    @include button-cta-font;
    text-transform: uppercase;
    transition: top 0.15s linear;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include screen(768px, 992px) {
      top: 45%;
    }

    @include max-screen(767px) {
      top: 50%;
    }

    span {
      border-bottom: 1px solid $white;
      padding-bottom: 1px;

      @include max-screen(767px) {
        padding-bottom: 2px;
      }
    }
  }

  .h1placeholder {
    opacity: 0;
    position: static;
    transform: none;
    margin: 10px 0;
  }
}

.number,
.readMore {
  opacity: 0;
  text-transform: uppercase;
  transition: opacity 0.1s linear;
}

.readMore {
  @include max-screen(992px) {
    opacity: 1;
  }
}

.numberShown,
.readMoreShown {
  opacity: 1;
}

.plusIcon {
  display: inline-block;
  margin-right: 7px;
  pointer-events: none;
  vertical-align: middle;
  height: 21px;
  width: 21px;

  svg {
    height: 21px;
    width: 21px;
  }

  path {
    stroke-width: 1.9px;
  }

  @include max-screen(767px) {
    vertical-align: top;
  }
}

.numberBottom {
  @include button-cta-font;
  bottom: 26px;
  color: $white;
  position: absolute;
  text-align: center;
  width: 100%;
}

.numberOpened {
  left: 20px;
  width: initial;

  @include max-screen(767px) {
    position: relative;
    top: 0;
    left: 0;
    text-align: left;
    margin: 0 0 30px 28px;
  }
}

.content {
  color: $white;
  float: right;
  top: 50%;
  transform: translateY(-50%);
  left: $content-margin;
  position: absolute;
  max-width: 255px;

  @include max-screen(767px) {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
    padding: 0 28px 30px;
    transform: none;
  }

  .h1 {
    @include subheader-font-demi;
    color: $white;
    margin-bottom: 16px;

    @include max-screen(767px) {
      margin: 0;
      max-width: 100%;
    }
  }

  .paragraph {
    @include body-font-2;
    margin-bottom: 22px;

    @include max-screen(767px) {
      @include body-font;
      margin: 14px 0 0;
    }

    a {
      color: $link-color-light;
      border-bottom: 1px solid $link-color-light;
    }
  }
}

.contentDark {
  .h1,
  .paragraph {
    color: $charcoal;
  }
}

.contentRight {
  left: initial;
  right: $content-margin;
}

.closeButton {
  position: absolute;
  top: 14px;
  right: 14px;

  @include max-screen(767px) {
    top: 10px;
    right: 10px;
  }
}

.closeIcon {
  height: 32px !important;
  width: 32px !important;
}

.leftArrow,
.rightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  path {
    width: 12px;
  }

  @include max-screen(767px) {
    display: none;
  }
}

.arrowSize {
  width: 35px !important;
}

.leftArrow {
  left: 12px;
}

.rightArrow {
  right: 12px;
}

.gradFadeIn {
  width: 0;
  height: 0;
  opacity: 0;
  transition: none;
}

.gradFadeInOn {
  width: initial;
  height: initial;
  opacity: 1;
  transition: opacity 0.2s linear;

  &:nth-child(1) {
    transition-delay: $trans-time;
  }

  &:nth-child(2) {
    transition-delay: $trans-time + 0.1s;
  }

  &:nth-child(3) {
    transition-delay: $trans-time + 0.2s;
  }
}

.jalousieDesktop {
  @include max-screen(767px) {
    display: none;
  }
}

.jalousieMobile {
  display: none;

  @include max-screen(767px) {
    display: block;
  }
}
