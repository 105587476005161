@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $white;
  position: relative;
  padding: 16px 20px 20px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.2);

  &.mobileLayout {
    box-shadow: none;
    padding-bottom: 10px;

    .buttonsContainer,
    .closeButton {
      display: none;
    }

    .checkboxOptionContainer {
      width: 50%;
      margin-bottom: 4px;
    }
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.resetButton {
  margin-right: 30px;

  :global(.Button__button) {
    color: $ash;
  }

  :global(.Button__disabled) {
    color: $grey-sky;
    opacity: 1;
  }
}

.confirmButton {
  :global(.Button__button) {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    border: 1px solid;
  }

  :global(.Button__disabled) {
    background-color: transparent !important;
    border-color: $grey-sky;
    color: $grey-sky;
    opacity: 1;
  }
}

.closeButton {
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 17px;
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }

  :global(.Button__button) {
    display: block;
  }

  svg {
    width: 20px;
    height: 20px;

    * {
      stroke-width: 5px;
      stroke: $charcoal;
    }
  }
}

.checkboxOptionsContainer {
  display: flex;
  flex-wrap: wrap;
}

.checkboxOptionContainer {
  display: flex;
  align-items: flex-start;
  width: 25%;
  margin-bottom: 8px;

  :global(.Checkbox__label) {
    vertical-align: text-top;
    color: $charcoal;
  }

  :global(.Checkbox__checked .Checkbox__checkbox:before) {
    border-color: $charcoal;
  }

  :global(.Checkbox__checked .Checkbox__checkbox:after) {
    background: $charcoal;
  }
}
