@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.subcatContainer {
  padding-bottom: 40px;
}

.heading {
  @include h3-font-demi;
  text-align: center;
  padding: 0 5px;
  margin: 50px 0 40px;

  @include max-screen(991px) {
    margin: 40px 0;
  }

  @include max-screen(767px) {
    margin: 40px 0 30px;
  }
}
