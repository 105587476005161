@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

/**
 * Default styling
 */
.defaultLayout {
  background: $mx-color-white;

  .container {
    padding: 30px 15px;
    margin-right: auto;
    margin-left: auto;
    @include body-font-2;
    color: $ash;

    @include max-screen(767px) {
      display: block;
      padding: 30px 10px 0;
      overflow: hidden;
      transition: max-height 300ms linear;

      &.collapsed {
        max-height: 535px;
      }
    }

    @media (min-width: 768px) {
      width: 738px;
    }

    @media (min-width: 992px) {
      width: 962px;
    }

    @media (min-width: 1200px) {
      width: 1170px;
    }

    div {
      margin-bottom: 20px;
    }

    h1 {
      @include h3-font-demi;
      padding: 20px 0;
      text-align: center;
      color: $ash;
    }

    h2 {
      @include maisonneue-demi;
      font-size: 1.6rem;
      line-height: 2rem;
      letter-spacing: 0.25rem;
      padding: 20px 0;
      text-transform: uppercase;
      text-align: center;
    }

    h3 {
      @include maisonneue-book;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2.3rem;
      margin-bottom: 20px;
    }

    h4 {
      @include maisonneue-book;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2.1rem;
      margin-bottom: 16px;
    }

    p {
      @include maisonneue-book;
      font-size: 1.4rem;
      line-height: 2.5rem;
      letter-spacing: 0.056rem;
      margin: 0px;
    }

    a[href] {
      color: $mx-color-secondary;

      &:hover {
        color: $mx-color-secondary;
        border-bottom: 1px solid $mx-color-secondary;
        cursor: pointer;
      }
    }

    ul {
      margin-left: 15px;
    }
  }
}

.htmlContentContainer {
  display: block;
  width: 100%;
}

.htmlContentContainer.twoColumnsContainer {
  display: flex;
  column-gap: 2rem;

  >div {
    flex: 1;
  }

  @include max-screen(767px) {
    display: block;
    column-gap: unset;
  }
}

.centeredHeadings {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
}

/**
 * SEO
 * (with `Read more` for mobile)
 */
.seoLayout {
  background: $mx-color-white;

  .seoContainer div {
    display: flex;

    @include max-screen(767px) {
      display: block;
    }
  }

  &.container p:first-child {
    margin-top: 0px !important;
    margin-bottom: 0;
  }

  &.container p:not(:first-of-type) {
    margin-top: 30px !important;
  }

  .container {
    color: $charcoal;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    position: relative;
    max-width: 1016px;
    margin: 0 auto;
    justify-content: space-between;

    @include max-screen(1280px) {
      margin: 0 132px;
    }

    h5,
    h1 {
      @include h3-font-demi;
      text-align: center;
      width: 100%;
      margin: 50px 20px 8px;
    }

    h2+h2 {
      display: none;
    }

    h2 {
      @include body-font;
      font-weight: normal;
      width: 290px;
      margin-top: 59px;

      &:before {
        content: '';
        border-top: 1px solid $ice;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin-top: -36px;
      }
    }

    p {
      @include body-font-2;
      margin-top: 59px;
    }

    h2+p {
      width: calc(100% - 290px);
      padding-left: 40px;
    }

    p+p {
      width: 100%;
      padding-left: 0;
    }

    a[href] {
      color: $pale-gold;
      text-decoration: underline;

      &:hover {
        color: $charcoal;
        text-decoration: underline;
      }

      &:active {
        color: $black;
      }
    }

    @include max-screen(992px) {
      margin: 0 30px;
      width: auto;

      h1 {
        margin: 30px 0 0;
      }

      h2 {
        margin-top: 67px;
        width: 200px;

        &:before {
          margin-top: -37px;
        }
      }

      p {
        margin-top: 67px;
        margin-bottom: 0;
      }

      h2+p {
        width: calc(100% - 200px);
        padding-left: 50px;
      }
    }

    @include max-screen(767px) {
      display: block;
      padding-bottom: 0;
      overflow: hidden;
      transition: max-height 300ms linear;
      margin: 0 10px;

      &.collapsed {
        max-height: 465px;
      }

      h1 br {
        display: none;
      }

      h2 {
        width: auto;
        margin-top: 46px;

        &:before {
          margin-top: -23px;
        }
      }

      p,
      h2+p {
        @include body-font;
        margin-top: 13px;
        padding: 0;
        width: auto;
      }
    }
  }
}

.centeredLayout {
  h1 {
    @include h1-font-demi;

    @include max-screen(991px) {
      @include h2-font-demi;
    }
  }

  h2 {
    @include h2-font-book;
  }

  h3 {
    &:first-of-type {
      margin-top: 40px;

      @include max-screen(991px) {
        margin-top: 30px;
      }
    }

    @include h3-font-demi;
  }

  h4 {
    margin-top: 58px;

    @include max-screen(991px) {
      margin-top: 30px;
    }

    @include subheader-font-demi;
  }

  h1,
  h2,
  h3 {
    text-align: center;
    padding-bottom: 16px;
  }

  p {
    @include body-font;
  }

  a[href] {
    color: $mx-color-secondary;
    border-bottom: solid 1px $mx-color-secondary;
    padding-bottom: 1px;

    &:hover {
      color: $mx-color-secondary;
      border-bottom: 1px solid $mx-color-secondary;
      cursor: pointer;
    }
  }

  .container {
    margin-right: auto;
    margin-left: auto;
    color: $charcoal;

    @media (min-width: 992px) {
      padding: 40px 0;
      width: 850px;
    }

    @media (max-width: 991px) {
      padding: 30px;
    }

    @media (max-width: 767px) {
      padding: 30px 10px;
    }
  }
}

.buttonWrapper {
  padding: 0 15px !important;

  &.centered {
    text-align: center;
  }
}

.readMoreBtnWrapper {
  display: none;

  @include max-screen(767px) {
    display: block;
    background-color: $mx-color-white;
    padding: 15px 0 35px;
    text-align: center;
  }
}

/**
 * Static (TBD)
 */
// .staticLayout {

// }

/**
 * Global Dark-mode override option
 * (has to stay at the bottom of this file)
 */
:global(.dark-mode) .container {
  background-color: $charcoal;

  h1,
  h2,
  p {
    color: $white;
  }

  a[href] {
    color: $pale-gold;
    text-decoration: underline;
    @include maisonneue-book;

    &:hover,
    &:active {
      color: $pale-gold !important;
      @include maisonneue-demi;
    }
  }

  .readMoreBtnWrapper {
    background: $charcoal;
  }
}

.codeContainer {
  text-align: center;
}

.entryLayout {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 850px;
    padding: 30px;

    @include max-screen(991px) {
      max-width: 100%;
      padding: 0;
    }
  }
}

.leftMobileLayout {
  @include max-screen(767px) {

    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li,
    ul,
    ol,
    blockquote,
    pre,
    table,
    td,
    th,
    form,
    fieldset,
    legend,
    label,
    button,
    input,
    textarea,
    select {
      text-align: left;
    }
  }
}

.largeLineSpacing {

  div,
  p,
  li {
    line-height: rem(38);
  }
}

.mediumLineSpacing {

  div,
  p,
  li {
    line-height: rem(29);
  }
}

.smallLineSpacing {

  div,
  p,
  li {
    line-height: rem(15);
  }
}

.contactUsLayout {
  background: #FAF6F2;

  .container {
    padding: 30px 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 768px) {
      width: 738px;
    }

    @media (min-width: 992px) {
      width: 962px;
    }

    @media (min-width: 1200px) {
      width: 1280px;
    }

    h1 {
      @include mx-font-h2;
      text-align: center;
      color: $charcoal;
      padding-top: 40px;
      margin-bottom: 30px;
    }

    h2 {
      font-size: rem(20);
      line-height: rem(25);
      font-family: $base-font-family;
      color: $charcoal;
      text-align: center;
    }

    h3 {
      margin-bottom: 20px;
      font-family: $base-font-family;
      color: $charcoal;
      font-size: rem(20);
      line-height: rem(25);

      &::before {
        width: 20px;
        height: 15px;
        background-size: 10px 20px;
        display: none;
        content: "";
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 10px;
      }
    }

    p {
      color: $charcoal;
      font-family: $base-font-family;
      font-size: rem(16);
      padding-bottom: 5px;
    }

    .htmlContentContainer.twoColumnsContainer {
      div:first-child {
        h3:first-child:before {
          display: inline-block;
          background-image: url("../../../../img/icons/contact-us/phone.png");
        }
      }

      div:last-child {
        h3::before {
          display: inline-block;
          background-image: url("../../../../img/icons/contact-us/email.png");
        }
      }
    }

    a {
      text-decoration: underline;
    }
  }
}