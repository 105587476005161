@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.redBackground {
  background-color: $dark-red;
}

.lightRedBackground {
  background-color: $light-red;
}

.greenBackground {
  background-color: $petrol-green;
}

.greyBackground {
  background-color: $charcoal;
}

.yellowBackground {
  background-color: $light-peach;
}

.warmYellowBackground {
  background-color: $warm-yellow;
}

.blueBackground {
  background-color: $dark-blue;
}

.neonPurpleBackground {
  background-color: $neon-purple;
}

.deepPinkBackground {
  background-color: $deep-pink;
}

.redBackground,
.lightRedBackground,
.greenBackground,
.greyBackground,
.blueBackground,
.neonPurpleBackground,
.deepPinkBackground {
  color: $white;

  .subheadline,
  .headline {
    color: $white !important;
  }

  a,
  .headline a {
    color: $white !important;
    text-decoration-color: $white !important;

    svg {
      * {
        stroke: $white;
      }
    }
  }

  a:hover,
  .headline a:hover {
    color: $eggshell !important;
    text-decoration-color: $eggshell !important;

    svg {
      * {
        stroke: $eggshell;
      }
    }
  }
}

.yellowBackground {
  color: $charcoal;

  a {
    color: $charcoal;
    text-decoration-color: $charcoal !important;

    svg {
      * {
        stroke: $charcoal;
      }
    }
  }

  a:hover,
  .headline a:hover {
    color: $black;
    text-decoration-color: $black !important;

    svg {
      * {
        stroke: $black;
      }
    }
  }

  .promoBannerButton:hover {
    color: $white;
  }
}

.warmYellowBackground {
  color: $black;

  .subheadline,
  .headline {
    color: $black !important;
  }

  a,
  .headline a {
    color: $black !important;
    text-decoration-color: $black !important;

    svg {
      * {
        stroke: $black;
      }
    }
  }

  a:hover,
  .headline a:hover {
    color: $black !important;
    text-decoration-color: $black !important;

    svg {
      * {
        stroke: $black;
      }
    }
  }
}

.greenBackground {
  .promoBannerButton:hover {
    background-color: $charcoal;
  }
}

.PromoBannerContainer {
  .headline,
  .subheadline,
  .collapsedContent {
    a {
      text-decoration: underline;
      text-underline-offset: 1px;
      text-decoration-thickness: 1px;
    }
  }
}

// Desktop
.fullWidth {
  width: 100%;
  height: 335px;
  display: flex;
  margin-top: 36px;
}

.promoText {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  padding: 30px;

  .headline {
    @include h3-font-demi;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    padding: 30px 0;
    justify-content: center;
    flex-direction: column;

    a {
      @include h3-font-demi;
    }
  }

  .subheadline {
    @include subheader-font-demi;
  }

  // Adds spacing between button and infolink (infolink in this case is 4th element)
  div:nth-child(4) {
    margin-top: 30px;
  }
}

.promoImage {
  width: 65%;
  height: 100%;
  display: inline-block;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.promoBannerButton {
  margin: 0 27px;
}

.infolink {
  @include body-font-4;

  a {
    border-bottom: none;
  }

  .text {
    border-bottom: solid 1px;
    margin-left: 5px;
  }

  svg {
    width: 1.7em;
    * {
      stroke-width: 4;
    }
  }
}

.collapsedContent {
  @include body-font-6;
  height: auto;
  min-height: 60px;
  text-align: left;
  padding: 20px 30px;
  display: none;

  @keyframes slideDown {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 3000px;
    }
  }

  &.open {
    display: block;
    animation: slideDown 600ms ease-in;

    @include min-screen(768px) {
      border-top: 1px solid $grey-sky;
    }
  }
}

// Tablet
@include max-screen(992px) {
  .fullWidth {
    height: 320px;
    margin-top: 30px;
  }

  .promoText {
    width: 50%;
    padding: 0;
    padding: 30px 20px 20px;

    .headline {
      @include subheader-font-demi;
      padding: 20px 0;

      a {
        @include subheader-font-demi;
      }
    }

    .subheadline {
      @include body-font-3;
    }

    .promoBannerButton {
      margin: 0;
    }
  }

  .promoImage {
    width: 50%;
  }

  .collapsedContent {
    font-size: rem(10);
    padding: 13px 17px;
  }
}

// Mobile
@include max-screen(767px) {
  .fullWidth {
    height: auto;
    display: block;
    margin-top: 20px;
  }

  .promoText {
    width: 100%;
    height: 100%;
    padding: 30px 20px 15px;

    // Adds spacing between button and infolink (infolink in this case is 4th element)
    div:nth-child(4) {
      margin-top: 24px;
    }
  }

  .promoImage {
    width: 100%;
    height: 215px;
    display: block;
  }

  .collapsedContent {
    padding: 8px 10px;
  }
}
