@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../../scss/functions';
@import '../../../../../../scss/config';
@import '../../../../../../scss/mixins';
@import '../../../../../../scss/vars';
@import '../../../../../../scss/media_queries';

.container {
  display: inline-block;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  height: 100%;
  position: relative;
}

.imageBlock {
  background-color: $light-ice;
  width: 100%;
  padding-bottom: 100%;
}

.textBlock {
  padding: 0;
  margin-top: 18px;

  .titlePlaceholder,
  .descriptionPlaceholder {
    background-color: $light-ice;
    padding: 20px 0 0;
  }

  .descriptionPlaceholder {
    margin-top: 15px;
  }
}

// Tablet
@include max-screen(992px) {
  .textBlock {
    margin-top: 10px;

    .titlePlaceholder,
    .descriptionPlaceholder {
      padding: 17px 0 0;
    }

    .descriptionPlaceholder {
      margin-top: 5px;
    }
  }
}

// Mobile
@include max-screen(767px) {
  .container {
    padding: 5px;
  }

  .textBlock {
    margin-top: 10px;
    .titlePlaceholder,
    .descriptionPlaceholder {
      padding: 25px 0 0;
    }

    .descriptionPlaceholder {
      margin-top: 5px;
    }
  }
}
