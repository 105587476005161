@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  max-width: 1250px;

  @include max-screen(1280px) {
    margin: 0 15px;
  }

  @include max-screen(768px) {
    margin: 0 20px;
  }

  @include max-screen(767px) {
    margin: 0;
  }

  &.even {
    .preview {
      width: 50%;
    }
  }
}

.heading {
  @include h3-font-demi;
  text-align: center;
  padding: 0 5px;
  margin: 50px 0 25px;

  @include max-screen(992px) {
    margin: 45px 0 20px;
  }

  @include max-screen(767px) {
    margin: 45px 0 25px;
  }
}

.bigHeading {
  @include h2-font-demi;
}

.preview {
  width: 33.333333%;

  &:nth-child(1),
  &:nth-child(2) {
    width: 50%;
  }

  @include max-screen(767px) {
    width: 100% !important;
  }

  .title {
    display: inline-block;
    margin: 0 -7px;
    a {
      @include link-primary;
    }
  }

  .subtitle {
    @include body-font-2;
    color: $text-color-primary;
    margin: 15px -7px 0;
  }

  @include max-screen(992px) {
    .subtitle {
      margin: 10px -7px 0;
    }
  }

  @include max-screen(767px) {
    .title {
      margin: 0 -10px;
    }

    .subtitle {
      margin: 15px -10px 0;
    }
  }
}

.darkText {
  .title > a {
    @include mx-font-h1;
    color: $charcoal;
    border-bottom: none;

    &:active,
    &:hover {
      border-bottom: none;
    }
  }

  a > .subtitle {
    @include mx-link;
  }
}
