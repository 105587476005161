@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background-color: $light-peach;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include max-screen(767px) {
    flex-direction: column;
  }
  a {
    width: fit-content;
    @include body-font-3;
    color: $ash;
    margin-bottom: 21px;
    border-bottom: 1px solid $ash;
    padding-bottom: 1px;
    text-transform: uppercase;
  }
}

.anchorColumn {
  display: flex;
  flex-direction: column;
  margin: 40px 50px 30px 50px;

  @include max-screen(767px) {
    margin: 0px;
    align-items: center;
    &:first-child {
      margin-top: 50px;
    }
    &:last-child {
      margin-bottom: 30px;
    }
  }
}
