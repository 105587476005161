@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$arrow: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MCA4MCI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjQkFCQUJBIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lam9pbj0idW5zZXQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgZD0iTTU0LjcgMTJMMjQuNSA0MC44bDMwLjYgMjguNCIgLz4gICAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lam9pbj0idW5zZXQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgZD0iTTU0LjcgMTJMMjQuNSA0MC44bDMwLjYgMjguNCIgLz48L3N2Zz4=';

@keyframes disappear {
  0% {
    z-index: 3;
  }
  100% {
    z-index: 1;
  }
}

.container {
  position: relative;

  .preload {
    position: relative;
    z-index: 3;
    animation: disappear 100ms linear;
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
  }

  .carouselWrapper {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.carousel {
  @include max-screen(767px) {
    max-height: none;
  }

  :global(.slick-list) {
    @include min-screen(769px) {
      flex-shrink: 0;
    }
  }

  :global(.slick-arrow),
  :global(.slick-arrow:before) {
    height: 45px;
    width: 40px;

    @include max-screen(1024px) {
      height: 35px;
      width: 30px;
    }
  }

  :global(.slick-arrow) {
    z-index: 1;

    @include max-screen(767px) {
      display: none;
    }

    &:before {
      background-image: url($arrow);

      @include max-screen(767px) {
        display: none;
      }
    }
  }

  :global(.slick-disabled) {
    opacity: 0.3;

    &:before {
      background-image: url($arrow);
    }
  }

  :global {
    .slick-prev {
      margin-left: 15px;
    }
    .slick-next {
      margin-right: 15px;
    }
  }

  :global(.slick-dots) {
    bottom: 28px;
    display: block !important;
    height: 30px;
    padding-right: 50px;
    position: absolute;
    right: 0;
    text-align: right;
    width: auto;

    li {
      vertical-align: middle;
    }

    button {
      background-color: $white;

      @include min-screen(769px) {
        border: 1px solid $grey-sky;
      }
    }

    :global(.slick-active) {
      button {
        background-color: $mx-color-primary;
      }
    }

    @include max-screen(768px) {
      padding-right: 20px;
    }

    @include max-screen(767px) {
      border: none;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%;
      bottom: 10px;

      button {
        background-color: $ice;
      }
    }
  }
}

.centerAlignment {
  :global(.slick-arrow),
  :global(.slick-arrow:before) {
    @include min-screen(768px) {
      height: 60px;
      width: 50px;
    }
  }

  :global(.slick-dots) {
    display: none !important;

    @include max-screen(767px) {
      display: block !important;
      padding: 30px 0 0;
    }
  }

  :global(.slick-active) {
    @include min-screen(768px) {
      button {
        background-color: $accent-colour !important;
      }
    }
  }

  :global(.slick-slide),
  .preload {
    overflow: hidden;
    padding: 0 6vw;

    img {
      width: 100%;
    }

    @include max-screen(768px) {
      max-height: 330px;
    }

    @include max-screen(767px) {
      padding: 0;
    }
  }

  .preload {
    @include max-screen(767px) {
      display: none;
    }
  }

  @include max-screen(767px) {
    .carouselWrapper {
      position: relative;
    }
  }
}
