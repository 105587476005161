@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  overflow: hidden;
  position: relative;

  &.darkMode {
    .title,
    .subtitle {
      color: $charcoal !important;
    }
  }

  &.lightMode {
    .title,
    .subtitle {
      color: $white !important;
    }
  }

  @include min-screen(768px) {
    display: flex;
    min-height: 420px;
    max-height: 72vh;
    align-items: center;
  }

  @include max-screen(767px) {
    height: 470px;
  }
}

.videoContainer {
  width: 100%;
  pointer-events: none;

  @include max-screen(767px) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.video {
  display: block;
  width: 100%;
  margin: auto;
  max-height: 100%;

  @include max-screen(767px) {
    height: 100%;
    width: auto;
  }
}

.textContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.textLayer {
  position: absolute;
  left: 0;
  bottom: 50%;
  z-index: 1;
  width: 50%;
  transform: translateY(50%);
  margin-left: 285px;
  text-align: left;
  max-width: 511px;

  @include max-screen(1440px) {
    margin-left: 150px;
  }

  @include max-screen(1280px) {
    margin-left: 135px;
  }

  @include max-screen(992px) {
    margin-left: 30px;
    max-width: 340px;
  }

  @include max-screen(767px) {
    margin: 10px auto 20px;
    top: 0;
    padding: 0 15px;
    bottom: 0;
    transform: none;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
}

.title {
  @include subheader-font-demi;
  margin-bottom: 10px;

  @include max-screen(992px) {
    margin-bottom: 15px;
  }

  @include max-screen(767px) {
    @include body-font-3;
    margin-bottom: 2px;
  }
}

.subtitle {
  @include h1-font-demi;

  @include max-screen(1280px) {
    @include h2-font-demi;
  }

  @include max-screen(992px) {
    @include h3-font-demi;
  }
}

.button {
  margin-top: 18px;
  position: relative;
  z-index: 2;

  @include max-screen(992px) {
    margin-top: 22px;
  }

  @include max-screen(767px) {
    margin-top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.playContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;

  @include max-screen(767px) {
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 70px;
    margin: 0;
  }
}

.playIconContainer {
  display: block;
}

.playIcon {
  height: 28px;
  width: 28px;
  background: $white;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px 8px;
  cursor: pointer;

  svg {
    height: 15px;
    width: 15px;

    * {
      fill: $black;
    }
  }
}

.playText {
  @include body-font-3;
  color: $white;
  cursor: pointer;
}

.overlayImage {
  @include fadeIn(200ms);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}
