@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.anchor {
  @include mx-link;
  color: $mx-color-secondary;
  font-size: rem(12);
  letter-spacing: 1px;
  line-height: inherit;
  display: block;
  text-align: center;
  margin-bottom: 10px;

  .anchorIcon {
    margin-left: 5px;
    height: 20px;
    width: 20px;

    path {
      stroke: $mx-color-secondary;
      stroke-width: 4;
    }
  }

  &:hover {
    .anchorIcon path {
      stroke: $mx-color-black;
    }
  }
}
