@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../../scss/functions';
@import '../../../../../../scss/config';
@import '../../../../../../scss/mixins';
@import '../../../../../../scss/vars';
@import '../../../../../../scss/media_queries';

.review {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  margin-bottom: 40px;
  background: $paper;

  @include max-screen(767px) {
    flex-direction: column;
  }
}

.reviewInfo {
  width: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @include max-screen(767px) {
    width: 100%;
  }

  .customerName {
    @include body-font-3;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-wrap: nowrap;
  }

  .stars {
    padding-bottom: 3px;
  }

  .date {
    @include body-font-4;
    text-wrap: nowrap;
  }
}

.reviewText {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .customerName {
    @include subheader-font-demi;
    font-size: 1.6rem;
    margin-bottom: 16px;
    margin-top: 10px;

    @include max-screen(767px) {
      margin-top: 20px;
    }
  }

  @include max-screen(767px) {
    width: 100%;
  }

  .text {
    @include body-font-2;
    margin-bottom: 0;
  }
}

.imageWrapper,
.imageWrapper picture {
  @include max-screen(767px) {
    margin-top: 20px;
  }
}

.fitImage {
  max-height: 500px;
  max-width: 700px;
  -o-object-fit: cover;
  object-fit: contain;
  height: fit-content;
  width: fit-content;

  @include max-screen(1023px) {
    max-width: 500px;
  }

  @include max-screen(991px) {
    max-width: 300px;
  }

  @include max-screen(767px) {
    width: 100%;
    max-width: 100%;
  }
}