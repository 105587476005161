@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.bannerCarouselContainer {
  overflow: hidden;
  margin: 40px 0;

  @include max-screen(767px) {
    margin: 30px 0;
  }
}

// Multiple HeroBlock's
.mainContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 1920px;
  margin: auto;

  @include max-screen(1920px) {
    margin-left: -2px;
  }

  @include max-screen(767px) {
    padding: 5px 0 0;
    margin-left: 0;
  }
}

.container {
  flex: 1 0 40%;
  min-width: 340px;
  min-height: 624px;
  margin-left: 2px;

  @include max-screen(767px) {
    margin-bottom: 10px;
    min-height: 420px;
    margin-left: 0px;
  }
}
