@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.category {
  @include min-screen(992px) {
    max-width: 1000px;
    width: 83%;
    margin: 0 auto;
  }
  margin-bottom: 5px;
  max-width: 700px;
  margin: 0 auto;

  @include max-screen(767px) {
    max-width: calc(100% - 40px);
  }
}
.categoryTitle {
  @include mx-font-title;
  color: $mx-color-grey-2;
  margin: 40px 0;
  padding-top: 10px;
  text-align: center;

  @include max-screen(367px) {
    margin: 30px 0 40px;
    padding-top: 10px;
  }
}
