@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../../scss/functions';
@import '../../../../../../scss/config';
@import '../../../../../../scss/mixins';
@import '../../../../../../scss/vars';
@import '../../../../../../scss/media_queries';

.containerPlaceholder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px auto 40px;
  max-width: 1250px;
}

.productPlaceholder {
  width: 33.33%;
}

// Tablet
@include max-screen(992px) {
  .containerPlaceholder {
    margin: 0px 15px 50px;
  }
  .productPlaceholder {
    width: 50%;
  }
}

// Mobile
@include max-screen(767px) {
  .containerPlaceholder {
    margin: 13px 5px 20px;
  }
}
