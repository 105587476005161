@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../../scss/functions';
@import '../../../../../../scss/config';
@import '../../../../../../scss/mixins';
@import '../../../../../../scss/vars';
@import '../../../../../../scss/media_queries';

.filtersContainer {
  margin-top: 10px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterTabsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.pillboxPlaceholder {
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid transparent;
  background-color: $light-ice;
  text-align: center;
  line-height: 2.5rem;
  margin: 5px;
  color: #646364;
  padding: 10px 30px;
  border-radius: 20px;
  flex-shrink: 0;
  outline: none;
}
.filtersBtnPlaceholder {
  display: none;
}

.filterTabsTitle {
  @include button-cta-font;
  text-transform: uppercase;
  color: $charcoal;
  margin-right: 15px;
  flex-shrink: 0;
}

// Tablet
@include max-screen(992px) {
  .filtersContainer {
    margin-top: 0;
  }
}

// Mobile
@include max-screen(767px) {
  .filterTabsContainer {
    display: none;
  }
  .filtersBtnPlaceholder {
    display: block;
    width: 155px;
    height: 41px;
    background-color: $light-ice;
    margin: 10px auto;
  }
}
