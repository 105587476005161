@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.productsWrap {
  margin-top: 30px;
  margin-bottom: 20px;

  @include max-screen(768px) {
    margin: 20px auto;
  }

  &.noMarginTop {
    margin-top: 0;
  }
}

.hideTopMargin {
  &.productsWrap {
    margin-top: -20px;
  }
}

.title {
  @include h3-font-demi;
  margin-bottom: 20px;
  text-align: center;
}

.showMoreBtn {
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.loaderContainer {
  display: inline-block;
}
