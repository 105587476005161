@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$loadingColor: #fafafa;

.banner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.container {
  overflow: hidden;
  position: relative;

  @include min-screen(1200px) {
    display: flex;
    min-height: 300px;
    max-height: 70vh;
    flex-direction: row;
    align-items: center;
  }
}

.button {
  margin-top: 18px;
  position: relative;
  z-index: 2;

  @include max-screen(992px) {
    margin-top: 23px;
  }

  @include max-screen(767px) {
    margin-top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.textContainer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @include max-screen(767px) {
    margin: 0 15px;
  }
}

.linkLayer {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lightMode {
  .title,
  .subtitle {
    color: $white;
  }

  &.overlay {
    background-image: linear-gradient(
      to left,
      transparent,
      rgba(0, 0, 0, 0.45)
    );
    @include max-screen(767px) {
      background-image: linear-gradient(
        to bottom,
        $black 14%,
        rgba(0, 0, 0, 0.73) 59%,
        rgba(0, 0, 0, 0)
      );
    }
  }
}

.darkMode {
  .title,
  .subtitle {
    color: $charcoal;
  }

  &.overlay {
    background-image: none !important;
  }
}

.noOverlayMode {
  .title,
  .subtitle {
    color: $white;
  }

  &.overlay {
    background-image: none !important;
  }
}

.textLayer {
  position: absolute;
  left: 0;
  bottom: 50%;
  text-align: left;
  transform: translateY(50%);
  z-index: 2;
  margin-left: 285px;
  max-width: 511px;

  @include max-screen(1440px) {
    margin-left: 150px;
  }
  @include max-screen(1280px) {
    margin-left: 135px;
    max-width: 340px;
  }
  @include max-screen(1024px) {
    margin-left: 80px;
  }

  @include max-screen(767px) {
    margin: 0 auto 25px;
    top: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
}

.title {
  @include subheader-font-demi;
  margin-bottom: 10px;

  @include max-screen(1024px) {
    margin-bottom: 15px;
  }

  @include max-screen(767px) {
    @include body-font-3;
    margin: 15px 0 5px;
  }
}

.subtitle {
  @include h1-font-demi;

  @include max-screen(1280px) {
    @include h2-font-demi;
  }

  @include max-screen(1024px) {
    @include h3-font-demi;
  }
}

.showPlacehoders {
  .title > span,
  .subtitle > span {
    background-color: $mx-color-white;
    color: transparent;
  }
}

.linksLayer {
  position: relative;
  z-index: 1;
  width: 100%;
}

.image {
  height: auto;
  width: 100%;

  @include max-screen(767px) {
    height: 470px;
    object-fit: cover;
  }
}

.banner.noImage {
  background: white;

  .container {
    min-height: 0;
  }

  .textContainer {
    position: relative;
    max-width: 1220px;
    margin: auto;

    @include max-screen(992px) {
      margin: 0 30px;
      max-width: 100%;
    }

    @include max-screen(767px) {
      margin: 0 10px;
    }
  }

  .textLayer {
    color: $charcoal;
    position: relative;
    padding: 0;
    margin: auto;
    text-align: center;
    transform: none;
    max-width: 100%;
  }

  .title {
    margin: 35px 0 17px;

    @include max-screen(992px) {
      margin: 20px 0 10px;
    }

    @include max-screen(767px) {
      margin: 30px 0 10px;
    }
  }

  .subtitle {
    margin-bottom: 50px;
    @include h2-font-demi;

    @include max-screen(992px) {
      margin-bottom: 35px;
      @include h3-font-demi;
    }

    @include max-screen(767px) {
      margin-bottom: 25px;
    }
  }
}

.overlay {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;

  @include max-screen(767px) {
    height: 241px;
    opacity: 0.24;
    top: 0;
  }
}

.anchors {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  pointer-events: all;
  position: relative;
  z-index: 2;

  .anchorItem {
    margin-bottom: 5px;

    &:not(:last-of-type) {
      border-right: 1px solid $mx-color-white;
      padding: 0 32px 0 4px;
      margin-right: 32px;
    }

    a {
      @include mx-link--secondary;
      color: $mx-color-white;
      border-bottom: 1px solid transparent;

      &:active,
      &:hover {
        color: $mx-color-white;
        border-bottom: 1px solid $mx-color-white;
      }
    }
  }

  @include max-screen(768px) {
    margin-top: 60px;

    .anchorItem:not(:last-of-type) {
      padding-right: 20px;
      margin-right: 20px;
    }
  }

  @include max-screen(767px) {
    display: none;
  }
}

.carousel {
  background: transparent;
  max-height: 560px;
  .showPlacehoders {
    .title > span,
    .subtitle > span {
      background: transparent;
    }
  }

  .textContainer {
    pointer-events: auto;
  }

  @include min-screen(1921px) {
    width: 100%;
  }

  @include max-screen(767px) {
    background-color: $white;

    .textContainer {
      position: absolute;

      .textLayer {
        bottom: 50px;
        margin-bottom: 0;
      }
    }
  }
}

.centered {
  @include max-screen(767px) {
    img {
      height: 270px;
      padding: 0 15px;
    }
  }
}
